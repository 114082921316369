// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import Format from "date-fns/format";
import ParseISO from "date-fns/parseISO";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var T = {};

function toString(x) {
  return x.toISOString();
}

function fromString(x) {
  return new Date(x);
}

function toJson(x) {
  return Json_Encode$JsonCombinators.date(x);
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.date, (function (prim) {
        return prim;
      }));

function fromJson(json) {
  return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, decoder));
}

function format(x, t) {
  return Format(x, t);
}

var Utc = {
  T: T,
  toString: toString,
  fromString: fromString,
  toJson: toJson,
  decoder: decoder,
  fromJson: fromJson,
  format: format
};

var T$1 = {};

function toString$1(x) {
  return Format(x, "yyyy-MM-dd");
}

function fromString$1(x) {
  return ParseISO(x);
}

function toJson$1(x) {
  return Format(x, "yyyy-MM-dd");
}

function toJsonOpt(x) {
  return Json_Encode$JsonCombinators.option(function (prim) {
                return prim;
              })(Belt_Option.map(x, toString$1));
}

var decoder$1 = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, (function (x) {
        return ParseISO(x);
      }));

function fromJson$1(json) {
  return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, decoder$1));
}

function format$1(x, t) {
  return Format(x, t);
}

var Naive = {
  T: T$1,
  toString: toString$1,
  fromString: fromString$1,
  toJson: toJson$1,
  toJsonOpt: toJsonOpt,
  decoder: decoder$1,
  fromJson: fromJson$1,
  format: format$1
};

export {
  Utc ,
  Naive ,
}
/* decoder Not a pure module */
