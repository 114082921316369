// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function Int($star) {
  var T = {};
  var eq = function (id1, id2) {
    return id1 === id2;
  };
  var toString = function (id) {
    return String(id);
  };
  var encoder = function (prim) {
    return prim;
  };
  var toJson = function (id) {
    return id;
  };
  var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.$$int, (function (prim) {
          return prim;
        }));
  var fromJson = function (json) {
    return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, decoder));
  };
  var toInt = function (id) {
    return id;
  };
  var cmp = Caml_obj.compare;
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var make = function () {
    return Belt_Map.make(Comparable);
  };
  var $$Map = {
    make: make
  };
  var make$1 = function () {
    return Belt_Set.make(Comparable);
  };
  var fromArray = function (x) {
    return Belt_Set.fromArray(x, Comparable);
  };
  var $$Set = {
    make: make$1,
    fromArray: fromArray
  };
  return {
          T: T,
          eq: eq,
          toString: toString,
          encoder: encoder,
          toJson: toJson,
          decoder: decoder,
          fromJson: fromJson,
          toInt: toInt,
          Comparable: Comparable,
          $$Map: $$Map,
          $$Set: $$Set
        };
}

function $$String$1($star) {
  var T = {};
  var eq = function (id1, id2) {
    return id1 === id2;
  };
  var toJson = function (id) {
    return id;
  };
  var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, (function (prim) {
          return prim;
        }));
  var fromJson = function (json) {
    return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, decoder));
  };
  var cmp = Caml_obj.compare;
  var Comparable = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var make = function () {
    return Belt_Map.make(Comparable);
  };
  var $$Map = {
    make: make
  };
  var make$1 = function () {
    return Belt_Set.make(Comparable);
  };
  var fromArray = function (x) {
    return Belt_Set.fromArray(x, Comparable);
  };
  var $$Set = {
    make: make$1,
    fromArray: fromArray
  };
  return {
          T: T,
          eq: eq,
          toJson: toJson,
          decoder: decoder,
          fromJson: fromJson,
          Comparable: Comparable,
          $$Map: $$Map,
          $$Set: $$Set
        };
}

var T = {};

function eq(id1, id2) {
  return id1 === id2;
}

function toString(id) {
  return String(id);
}

function fromString(id) {
  return Belt_Option.getExn(Belt_Int.fromString(id));
}

function encoder(prim) {
  return prim;
}

function toJson(id) {
  return id;
}

function toJsonOpt(id) {
  return Json_Encode$JsonCombinators.option(encoder)(Belt_Option.map(id, (function (prim) {
                    return prim;
                  })));
}

function toJsonArray(ids) {
  return Json_Encode$JsonCombinators.array(encoder)(Belt_Array.map(ids, (function (prim) {
                    return prim;
                  })));
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.$$int, (function (prim) {
        return prim;
      }));

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var cmp = Caml_obj.compare;

var Comparable = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function make() {
  return Belt_Map.make(Comparable);
}

var $$Map = {
  make: make
};

function make$1() {
  return Belt_Set.make(Comparable);
}

function fromArray(x) {
  return Belt_Set.fromArray(x, Comparable);
}

var $$Set = {
  make: make$1,
  fromArray: fromArray
};

export {
  Int ,
  $$String$1 as $$String,
  T ,
  eq ,
  toString ,
  fromString ,
  encoder ,
  toJson ,
  toJsonOpt ,
  toJsonArray ,
  decoder ,
  fromJson ,
  Comparable ,
  $$Map ,
  $$Set ,
}
/* decoder Not a pure module */
