// extracted by mini-css-extract-plugin
export var busy = "Button__busy__se8U4";
export var button = "Button__button__q1G4V";
export var colorClear = "Button__colorClear__mMjVb";
export var colorDarkerGray = "Button__colorDarkerGray__IAq8O";
export var colorGhostClear = "Button__colorGhostClear__PmnyK";
export var colorGhostPink = "Button__colorGhostPink__IsVLq";
export var colorGhostPrimary = "Button__colorGhostPrimary__FeHCq";
export var colorGhostRed = "Button__colorGhostRed___JWPq";
export var colorGhostRedDarker = "Button__colorGhostRedDarker__tLEEX";
export var colorGhostTeal = "Button__colorGhostTeal__B0Ge5";
export var colorGray = "Button__colorGray__tAI6V";
export var colorOrange = "Button__colorOrange__Bx7EG";
export var colorPink = "Button__colorPink__i3BXm";
export var colorPrimary = "Button__colorPrimary__UKhKJ";
export var colorRed = "Button__colorRed__PWByV";
export var colorRedDarker = "Button__colorRedDarker__Q0K3s";
export var colorTeal = "Button__colorTeal__MGhVa";
export var colorWhite = "Button__colorWhite___YKXV";
export var column = "Button__column__qJCW0";
export var expanded = "Button__expanded__lNYJk";
export var iconSuffixComponent = "Button__iconSuffixComponent__J2wit";
export var marginLeft = "Button__marginLeft__CWXQX";
export var marginRight = "Button__marginRight__JYWHj";
export var row = "Button__row__fSVqT";
export var sizeLg = "Button__sizeLg__nbVdg";
export var sizeMd = "Button__sizeMd__pu6ef";
export var sizeSm = "Button__sizeSm__Vt43J";
export var sizeXs = "Button__sizeXs__bhYVc";
export var visuallyDisabled = "Button__visuallyDisabled__EVH03";
export var wrapper = "Button__wrapper__nHhEx";